iframe
{
    display: none;
}

.root {
  margin: 0;
}

.red {
  background: #ff5555;
}
.greyBg {
  background-color: #4d597a;
}
.bluebg {
  background: #0061fe;
}
.whiteBg {
  background-color: #ffffff;
}
::-webkit-scrollbar {
  display: none;
}
.main-container2 {
  width: 100vw;
  height: 100vh; 
  background-color: #111827;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  scroll-behavior: initial;

  @media only screen and (max-width: 600px) {
    margin-top: 80px;
    height: calc(100vh - 210px);
    overflow-y: scroll;
    padding-top: 0;
    position: fixed;
  }
}

.recording-message {
  width: 100%;
  position: absolute;
  top: 0;
  height: 50px;
  background: #4d597a;
  color: #ffffff;
  z-index: 1500;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
}

.child1 {
  width: 100vw;
  z-index: 1000;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  height: 50px;
  padding: 0 10px;
  top: 0;
  background-color: #111827;

  @media only screen and (min-width: 600px) {
    padding: 40px;
  }
  .title {
    height: 100%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    display: flex;
    align-items: center;
    color: #ffffff;
    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
  .toggle-list-btn {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    cursor: pointer;
    background: #4d597a;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dot {
      margin: 0.1rem;
      width: 40%;
    }

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }

  .leaveFromTop {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5px;

    .call {
      width: 50%;
    }

    @media only screen and (min-width: 600px) {
      display: none;
    }
  }
  .recording-indicator {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .record {
      width: 1rem;
      height: 1rem;
    }
  }
}

.videoPlaceholder {
  background-color: #111827;
  font-size: 1.5rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  width: 480px;
  background-color: #253351;
  z-index: 10;
}

#video-container {
  width: 100%;
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  position: initial;

  @media only screen and (max-width: 600px) {
    height: auto;
    width: auto;
  }
  #contentshare-container {
    width: calc(100% - 210px);
    height: 100%;

    > div {
      background-color: #111827;
    }

    .contentshare-container-attendeee_name {
      position: absolute;
      bottom: 20px;
      left: 20px;

      > span {
        color: grey;
        font-size: 16px;
        text-transform: capitalize;
      }
    }

    @media only screen and (max-width: 600px) {
      width: 98vw;
      position: absolute;
      z-index: 1000;
      left: 1%;
      height: calc(100vh - 210px);
      // top: 80px;
    }
  }

  #remote-video-container {
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    display: grid;
    grid-gap: 0.4em;
    margin-left: 10px;
    margin-right: 10px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(300px, 1fr));

    &.sidebar {
      height: calc(100vh - 172px);
    }

    &.count ::-webkit-scrollbar {
      display: none;
    }

    @media only screen and (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr) !important;
      grid-template-rows: initial !important;
      grid-gap: 0.2em;
    }

    .attendee-video-container {
      display: flex;
      align-items: center;
      height: 300px;
      width: 100%;
      position: relative;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      background-color: #1D2432;

      > div:not(.tileControls) {
        width: 100%;
        height: 100%;
        margin: 0 auto;

        .ch-video {
          object-fit: cover !important;
          width: 100% !important;
          height: 100% !important;
          background-color: transparent !important;
        }
      }
      @media only screen and (min-width: 1000px) {
        > div:not(.tileControls) {
          max-width: 80vw;
        }
      }
      @media only screen and (max-width: 600px) {
        width: 50vw;
        height: 50vw;
        .userCircle {
          width: 100px !important;
          height: 100px !important;
          font-size: 40px !important;
          font-weight: 500 !important;
        }

        .altraImage {
          width: 50px;
          height: 50px;
        }
      }

      &.localUser {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 270px;
        height: 270px;
        z-index: 10;
        border: 5px solid #111827;

        @media only screen and (max-width: 600px) {
          position: relative;
          height: 50vw;
          width: 50vw;
          border: none;
        }
      }

      .tileControls {
        display: flex;
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
        width: fit-content;
        justify-content: space-between;
        align-items: center;
      }

      .nameContainer {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
        text-align: center;

        &--small {
          font-size: 0.6rem;
        }
      }

      .nameText {
        margin-right: 10px;
        color: #ffffff;
        padding: 10px 14px;
        background-color: #777882;
        opacity: 0.6;
        border-radius: 30px;
        width: fit-content;

        &--small {
          padding: 8px 10px;
        }
        @media only screen and (max-width: 600px) {
          padding: 6px 10px;
        }
      }
      .mute-status {
        background: #dcdcdc;

        width: 2.2em;
        height: 2.2em;
        border-radius: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        &--small {
          width: 1.5em;
          height: 1.5em;
        }

        @media only screen and (max-width: 600px) {
          width: 1.8em;
          height: 1.8em;
        }

        .mut {
          width: 60%;
          height: 60%;
        }
        .unmut {
          width: 60%;
          height: 60%;
        }
      }
    }
  }
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-buttons-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  z-index: 1000;

  > div {
    margin-left: 20px;
  }

  #video {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .videoff,
    .videoon {
      width: 40%;
      height: 40%;
    }
  }

  #leave {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .call {
      width: 40%;
      height: 40%;
    }
  }

  #voice {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .mute1 {
      width: 40%;
      height: 40%;
    }
    .mute2 {
      width: 40%;
      height: 40%;
      position: absolute;
      left: 28.33%;
      right: 8.33%;
      top: 28.55%;
      bottom: 9.55%;
    }
  }
  #screen-share {
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .screen {
      width: 40%;
      height: 40%;
    }
  }
}

#roster-container {
  position: absolute;
  z-index: 1000;
  right: 0;
  width: 380px;
  max-width: 100%;
  height: 80vh;
  background: #111827;
  border-top: 0.5px solid #ffffff33;
  padding: 50px 50px 0;

  .roster-close-button {
    background-color: #111827;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
    margin-left: -10px;
  }

  .roster-close-button-text {
    color: white;
    font-weight: bold;
    font-size: 16px;
  }

  .roster-close-button-icon {
    color: white;
    padding: 0;
  }

  @media screen and (max-width: 600px) {
    height: 100vh;
    position: fixed;
    padding-top: 40px;
    width: 100%;
  }
  aside {
    width: 100%;
    height: calc(100% - 50px);
    overflow: hidden;
    background-color: #111827;
    border: none !important;
    box-shadow: none !important;
    overflow-y: scroll;

    @media screen and (max-width: 600px) {
      padding-bottom: 120px;
    }
  }

  div {
    margin: 0;
    background-color: #111827;
    color: white;
  }

  .roster-attendee {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-top: 26px;

    .profile-pic {
      border-radius: 50%;
      background-color: grey;
      color: white;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4px;
      text-transform: uppercase;
    }

    .roster-attendee-name-role-container {
      display: flex;
      flex-direction: column;
      margin-left: 20px;

      > div {
        margin-top: 5px;
      }

      .roster-attendee-name-role {
        font-size: 14px;
        color: #ffffff99;
      }
    }

    .roster-attendee-item {
      padding: 0;
      color: white;
      font-size: 18;
      font-weight: bold;
      text-transform: capitalize;
    }

    .ch-mic,
    .Svg {
      display: none;
    }
  }
}

.container {
  top: 0px;
  left: 0px;
  width: 80%;
  opacity: 1;
}

.iconcontainer {
  width: 200px;
  height: 50px;
  align-items: center;
  background-color: #ff5555;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #0061fe;
  font-weight: 600;
  font-size: 25px;
}
.iconImage {
  margin-right: 5px;
}

@media screen and (min-width: 600px) {
  .container {
    min-width: 100%;
    align-items: center;
  }
}

.toggleChatFromBottom {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width:600px){
    display: none;
  }
}

#video-container #remote-video-container .attendee-video-container {
  border: 5px solid transparent;

  &.isSpeaking {
    border: 5px solid #0b4d6c;
  }
}

.noInternetErrMsgContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;

  .meetingEndedInfoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .meetingEndedInfoTitle {
    font-size: 24px;
    color: white;
    text-transform: capitalize;
  }

  .meetingEndedInfoDuration {
    font-size: 16px;
    color: #ffffff99;
    margin-top: 16px;
    text-transform: capitalize;
  }

  .meetingEndedDismissButton {
    background-color: #0ea5e9;
    color: white;
    font-size: 16px;
    text-transform: capitalize;
    margin-top: 60px;
    width: 124px;
    height: 50px;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
  }
}

.isSpeaking {
  border: 5px solid #0b4d6c;
}
