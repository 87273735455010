@import url("https://fonts.googleapis.com/css?family=Poppins");

@import url("https://fonts.googleapis.com/css?family=Courgette");

@font-face {
  font-family: "OCRA";
  src: url(./assets/fonts/OCRAEXT.TTF) format("truetype");
}

@font-face {
  font-family: "SFPro";
  src: url(./assets/fonts/FontsFree-Net-SFProDisplay-Black.ttf)
    format("truetype");
}

@font-face {
  font-family: "SFPro-r";
  src: url(./assets/fonts/SFProDisplay-Regular.ttf) format("truetype");
}


/* desktop:1200px; col-xl
laptop:1024px;     col-lg
tablet:768px;      col-md
phone:480px;       col-sm   */

h1 {
  font-family: "SFPro", Poppins;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.banner {
  /* width: 100vw; */
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.bannerTitle {
  font-size: 70px;
}

.bannerContent {
  font-size: 24px;
  margin-top: 26px;
}

.contact-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.contact-container {
  margin-left: 13%;
  display: flex;
  width: 70%;
  background-color: #0ea5e9;
}

.contact-inner-container {
  width: 50%;
}

.contact-image-container {
  width: 50%;
}

.contact-right {
  width: 35%;
  background-color: aqua;
}
p#small-uiux {
  font-size: 8px;
  margin-bottom: 10px;
}
/**contact page**/
* {
  box-sizing: border-box;
}

/* hr part */
.footer-divider {
  height: 1px;
  background-color: black;
  border: none;
}

/* Style inputs */
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px;
  /* border: 1px solid #ccc; */
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #04aa6d;
  color: white;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

/* Style the container/contact section */
.container3 {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 10px;
}

.para-box {
  margin-top: 12px;
  width: 35vw;
  align-items: left;
}
.para-box p {
  font-size: 16px;
}

.main-left h1 {
  margin-top: 60px;
}

/* get a demo arrow button */
.arrowdash {
  position: relative;
  width: 50px;
  background-color: blueviolet;
  display: inline-block;
  vertical-align: center;
}

.dash {
  width: 38px;
  height: 1.4px;
  margin: -8px;
  margin-left: 3px;
  top: 0px;
  display: inline-block;
  background-color: white;
  position: absolute;
  transition: width 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out;
}

.triangle-right {
  top: 0px;
  transform: translateX(44px) translateY(-45%);
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid white;
  border-right: 6px solid transparent;
  position: absolute;
  margin: -7.1px;
  opacity: 1;
}

/* Create two columns that float next to eachother */
.column {
  float: left;
  width: 50%;
  margin-top: 6px;
  padding: 20px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}
/* ************* */

.cpy {
  font-size: 1rem;
}

.cpy h6 {
  font-weight: 800;
}

.cpy-1 {
  font-size: 1rem;
}

.cpy-1 h6 {
  font-weight: 800;
}

/* the width in copyright maybe cause overflow problem */
.copyright {
  /* width: 80%; */
  /* position: absolute; */
  text-align: center;
  
}

.page-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  font-family: "SFPro-r", Poppins;
  /* padding: 0 40px; */
  /* background-color: #04AA6D; */
}
.content-wrap {
  flex: 1;
}

.footer-main {
  text-align: left;
  color: black;
}

.home-container {
  margin-top: 100px;
}

/* footer section */

.footer-content {
  display: flex;
  justify-content: space-between;
  /* gap : 5%; */
}

.upper-part {
  color: black;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-top: 100px;
  text-align: left;
}

/* Two writing section part */
.upper-part h2 {
  font-weight: 900;
  font-size: 36px;
  color: black;
  text-align: left;
  /* margin-bottom: 2rem; */
  font-family: "SFPro", Poppins;
  /* background-color: #0ea5e9; */
}

.upper-part .foot-para {
  font-weight: bold;
  font-size: 20px;
}

.upper-part h2 {
  margin-bottom: 50px;
}

.upper-part p {
  font-size: 24px;
}

.para-box p {
  font-size: 22px;
  font-family: "SFPro-r", Poppins;
  margin-top: 30px;
}
.uiux p {
  font-size: 10px;
  font-family: "SFPro-r", Poppins;
}

.contact-footer {
  display: flex;
  justify-content: space-between;
  gap: 12.5%;
}

/* BACK01 */
.left-box {
  width: 35vw;
  align-items: left;
  /* background-color: orange; */
}

/* Section 01 */
.right-box {
  /* border: 1px solid black;
    border-radius: 5px; 
    box-shadow: -10px 10px #0ea5e9;
    background: black; */
  width: 40%;
  /* background-color: aqua; */
}

/* text for footer with logo part */

.span {
  font-family: "OCRA", cursive;
  font-size: 18px;
  color: #111827;
  padding: 0.5%;
  font-weight: bold;
  margin-left: 6px;
}
/* section 01 */
.mylogo {
  display: flex;
}

/* logo part in the footer also in contact page
  .logo {
    width: 200px;
    position: absolute; 
    top: 0; 
    padding: 0.5%; 
    display: flex;
  } */

.cpy {
  width: 30%;
}

.cpy-1 {
  width: 20%;
}
.logo-f {
  display: flex;
  width: 233px;
}

/* parallax image */
img {
  max-width: 100%;
}

.parallax-image {
  /* display:flex; */
  margin: 0px -100px;
  justify-content: center;
  align-items: center;
  background-color: #111827;
  overflow: hidden;
  /* background-image: url(../src/images/Group.png); */
  height: 500px;
  margin-top: 100px;
  margin-bottom: 10px;
  background-size: contain;
  position: relative;
  z-index: 1;
}

.back-left-animate {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  background-size: contain;
  position: absolute;
  z-index: 5;

  animation: curtain-in 3s;
  animation-direction: normal;
  animation-fill-mode: forwards;

  background-image: url(../src/images/Group.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: 100% 100%;
}

/* left background images */
@keyframes curtain-in {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

.back-right-animate {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  background-size: contain;
  position: absolute;
  right: 0;
  z-index: 5;
  animation: curtain-right 3s;
  animation-direction: normal;
  animation-fill-mode: forwards;

  background-image: url(../src/images/Group.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  background-position: 0% 100%;
}

/* right background image */
/* @keyframes curtain-right {
  0% {
    right: -100%;
  }
  100% {
    right: 0%;
  }
} */

.overlay {
  position: absolute;
  background-color: black;
  height: 100%;
  width: 100%;
  opacity: 0.8;
}

.first-image {
  width: 20%;
  /* height:580px; */
  position: absolute;
  top: 12.5%;
  left: 100px;
  z-index: 10;
  border-radius: 25px;
  /* border: 3px solid #0ea5e9;
    overflow: hidden; */
}

.second-image {
  width: 20%;
  /* height:580px; */
  position: absolute;
  top: 12.5%;
  left: 100px;
  z-index: 20;
  border-radius: 25px;
  /* border: 3px solid #0ea5e9;
    overflow: hidden; */

  animation-name: slider-2;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-delay: 4s;
}

.third-image {
  width: 20%;
  /* height:580px; */
  position: absolute;
  top: 12.5%;
  left: 100px;
  z-index: 30;
  border-radius: 25px;
  /* border: 3px solid #0ea5e9;
    overflow: hidden; */

  animation-name: slider-3;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-delay: 4s;
}

.fourth-image {
  width: 20%;
  /* height:580px; */
  position: absolute;
  top: 12.5%;
  left: 100px;
  z-index: 40;
  border-radius: 25px;
  /* border: 3px solid #0ea5e9;
    overflow: hidden; */

  animation-name: slider-4;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-delay: 4s;
}

/* backward animation */
.reverse-2 {
  left: 380px;
  animation-name: reverse-slide-2;
  animation-duration: 4s;
  animation-fill-mode: both;
}

.reverse-3 {
  left: 660px;
  animation-name: reverse-slide-3;
  animation-duration: 4s;
  animation-fill-mode: both;
}

.reverse-4 {
  left: 940px;
  animation-name: reverse-slide-4;
  animation-duration: 4s;
  animation-fill-mode: both;
}
/* ****************************** */
@keyframes reverse-slide-2 {
  0% {
    left: 27.5%;
    top: 12.5%;
  }
  100% {
    left: 100px;
    top: 12.5%;
  }
}

@keyframes reverse-slide-3 {
  0% {
    left: 48%;
    top: 12.5%;
  }
  100% {
    left: 100px;
    top: 12.5%;
  }
}

@keyframes reverse-slide-4 {
  0% {
    left: 68.5%;
    top: 12.5%;
  }
  100% {
    left: 100px;
    top: 12.5%;
  }
}
/* ******************************* */

.second-image {
  width: 20%;
  /* height:580px; */
  position: absolute;
  top: 12.5%;
  left: 100px;
  z-index: 20;
  border-radius: 25px;
  /* border: 3px solid #0ea5e9;
    overflow: hidden; */

  animation-name: slider-2;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

.third-image {
  width: 20%;
  /* height:580px; */
  position: absolute;
  top: 12.5%;
  left: 100px;
  z-index: 30;
  border-radius: 25px;
  /* border: 3px solid #0ea5e9;
    overflow: hidden; */

  animation-name: slider-3;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

.fourth-image {
  width: 20%;
  /* height:580px; */
  position: absolute;
  top: 12.5%;
  left: 100px;
  z-index: 40;
  border-radius: 25px;
  /* border: 3px solid #0ea5e9;
    overflow: hidden; */

  animation-name: slider-4;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}
/* forward animation */
@keyframes slider-2 {
  0% {
    left: 100px;
    top: 12.5%;
  }
  100% {
    left: 28%;
    top: 12.5%;
  }
}

@keyframes slider-3 {
  0% {
    left: 100px;
    top: 12.5%;
  }
  100% {
    left: 48%;
    top: 12.5%;
  }
}

@keyframes slider-4 {
  0% {
    left: 100px;
    top: 12.5%;
  }
  100% {
    left: 68%;
    top: 12.5%;
  }
}

/* backward animation */
.reverse-2 {
  left: 380px;
  animation-name: reverse-slide-2;
  animation-duration: 4s;
  animation-fill-mode: both;
}

.reverse-3 {
  left: 660px;
  animation-name: reverse-slide-3;
  animation-duration: 4s;
  animation-fill-mode: both;
}

.reverse-4 {
  left: 940px;
  animation-name: reverse-slide-4;
  animation-duration: 4s;
  animation-fill-mode: both;
}
/* ****************************** */
@keyframes reverse-slide-2 {
  0% {
    left: 27.5%;
    top: 12.5%;
  }
  100% {
    left: 100px;
    top: 12.5%;
  }
}

@keyframes reverse-slide-3 {
  0% {
    left: 48%;
    top: 12.5%;
  }
  100% {
    left: 100px;
    top: 12.5%;
  }
}

@keyframes reverse-slide-4 {
  0% {
    left: 68.5%;
    top: 12.5%;
  }
  100% {
    left: 100px;
    top: 12.5%;
  }
}
/* ******************************* */

.reverse-4 {
  left: 940px;
  animation-name: reverse-slide-4;
  animation-duration: 4s;
  animation-fill-mode: both;
}
/* ****************************** */
@keyframes reverse-slide-2 {
  0% {
    left: 27.5%;
    top: 12.5%;
  }
  100% {
    left: 100px;
    top: 12.5%;
  }
}

@keyframes reverse-slide-3 {
  0% {
    left: 48%;
    top: 12.5%;
  }
  100% {
    left: 100px;
    top: 12.5%;
  }
}

@keyframes reverse-slide-4 {
  0% {
    left: 68.5%;
    top: 12.5%;
  }
  100% {
    left: 100px;
    top: 12.5%;
  }
}
/* ******************************* */

.main-container-m {
  margin-top: 50px;
  display: flex;
}
.inner-container {
  width: 50%;
  justify-content: center;
}
/* altra exprience section */
.main-left {
  text-align: left;
  background: #1d2432;
  padding-left: 50px;
  padding-top: 70px;
  color: white;
  padding-bottom: 70px;
}

/* for two screenshot images */
.main-right {
  background: #0ea5e9;
  padding-left: 30px;
  padding-top: 30px;
  display: flex;
  padding: 20px;
  align-items: center;
}
.learn-button {
  margin-top: 166px;
  border: none;
  transition: 1s ease-in-out 2s slideInFromLeft;
}

.contact-image {
  width: 40%;
}

.slide-in-rl-out{
  animation-name: slideInRlOut;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes slideInRlOut {
 0% {transform: translateX(-350px); }
 100% { transform: translateX(0);} 
}
 

.slide-in-td {
  animation-name: slideInTopToDown;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

.slide-in-dt {
  animation-name: slideInDownToTop;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}

@keyframes slideInTopToDown {
  0% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes slideInDownToTop {
  0% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* main container learn more button with out hover */
.defaultButton-1,
.defaultButton-1:focus {
  border-radius: 8px;
  text-decoration: none;
  display: inline-block;
  background-color: #0ea5e9;
  padding: 12px 22px;
  color: white;
}

.arrowdash-1 {
  position: relative;
  width: 50px;
  margin-left: 0px;
  display: inline-block;
  vertical-align: center;
}

.arrowdash-1 {
  position: relative;
  width: 50px;
  margin-left: 0px;
  display: inline-block;
  vertical-align: center;
}

.dash-1 {
  width: 38px;
  height: 1px;
  margin: -8px;
  /* margin-left:3px; */
  top: 0px;
  display: inline-block;
  background-color: white;
  position: absolute;
  transition: width 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out;
}

.triangle-right-1 {
  top: 0px;
  transform: translateX(44px) translateY(-45%);
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 6px solid white;
  border-right: 6px solid transparent;
  position: absolute;
  margin: -7.1px;
  margin-left: -15px;
  opacity: 1;
}

/* ********************************************* */

.defaultButton,
.defaultButton:focus {
  border-radius: 8px;
  text-decoration: none;
  display: inline-block;
  background-color: #0ea5e9;
  padding: 12px 20px;
  color: white;
}

.defaultButton:hover {
  color: white;
  width: 200px;
  text-decoration: none;
  transition: 0.8s ease-in-out;
}
.defaultButton:hover .dash {
  width: 125%;
  transition: 0.3s ease-out;
}
.defaultButton:hover .triangle-right {
  opacity: 0;
  transition: 0.3s ease-out;
}

/* ******************** */
.photo {
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
  height: 460px;
}

.navbar-container {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  /* background-color: #04AA6D; */
}
.uiux-wrapper {
  display: flex;
  align-items: center;
  margin-top: 40px;
}

.uiux {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  background-color: white;
  margin-right: 10px;
}

.uiux-content {
  font-size: 14px;
  color: #ffffffcc;
}

.main-right {
  background: #0ea5e9;
}

.btn1 {
  padding: 5px 20px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 8px;
}
.para {
  height: auto;
  width: 60%;
  text-align: left;
  margin-bottom: 130px;
}

.contactform_title {
  width: 20%;
  display: flex;
  justify-content: left;
}

.demo-button {
  margin-top: 60px;
  background: #0ea5e9;
  border-radius: 5px;
  border: none;
  color: white;
  /* padding: 10px 20px; */
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
}

.verticalSpace50px {
  padding: 50px 0;
}

.mainLeftTitle {
  font-size: 48px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* *************************************************************** */
/* media query for part 1 */
@media only screen and (max-width: 600px) {
  h1 {
    font-size: 40px;
    font-weight: 900;
    align-items: left;
    font-family: "SFPro", SFPro-r;
  }
  .banner {
    padding-top: 80px;
    height: 1000px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
    gap: 25px;
    /* align-items: left;  center-align was in banner for middle of both lefta dn right box   */
  }

  .left-box {
    width: 95%;
    height: 450px;
    align-items: left;
    text-align: left;
  }
  .right-box {
    min-width: 100vw;
    padding: 0px 25px 50px 0px;
    margin-top: 25px;
    height: 500px;
    display: block;
  }
}
/* media query for part-1(banner) 600px-1000px */

@media only screen and (min-width: 600px) and (max-width: 900px) {
  h1 {
    font-size: 40px;
    font-weight: 900;
    align-items: left;
    font-family: "SFPro", SFPro-r;
  }
  .banner {
    padding-top: 20px;
    height: 1000px;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding-bottom: 50px;
    padding-left: 10px;
    padding-right: 10px;
    gap: 0px;
  }

  .left-box {
    width: 95%;
    height: 500px;
    align-items: left;
    text-align: left;
  }
  .right-box {
    min-width: 80%;
    padding: 0px 25px 50px 0px;
    margin-top: 10px;
    margin-bottom: 20px;
    height: 500px;
    display: block;
  }
}

/* Media Query for part-1(banner) Large screens */
@media (min-width: 1400px) {
  h1 {
    font-size: 60px;
    font-weight: 900;
    align-items: left;
    font-family: "SFPro", SFPro-r;
  }
  .banner {
    /* padding-top:80px; */
    height: 800px;
    display: flex;
    align-items: left;
    justify-content: center;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    gap: 0px;
  }
  .bannerContent {
    font-size: 35px !important;
    margin-top: 20px;
  }

  .left-box {
    width: 95%;
    height: 500px;
    align-items: left;
    text-align: left;
  }

  .right-box {
    min-width: 50%;
    padding: 0px 25px 50px 0px;
    margin-top: 25px;
    /* height:500px; */
    display: block;
  }
}

/* *************************************************************** */
/* media query for part 2 -(Altra Experience)*/

@media only screen and (max-width: 600px) {
  .main-container-m {
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }
  .inner-container {
    align-items: center;
    width: 100%;
  }
  .main-left {
    align-items: center;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-top: 40px;
  }
  .main-left h1 {
    margin-top: 30px;
  }
  .para-box {
    margin-top: 7px;
    width: 95%;
    align-items: left;
  }
  .demo-button {
    margin-top: 10px;
    width: 200px;
    height: 50px;
    font-size: 20px;
    font-weight: 200;
  }
  .learn-button {
    margin-top: 40px;
  }
  .main-right {
    margin-bottom: 20px;
  }
  .photo {
    display: flex;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
/* media query for part2(Altra-Experience) 600px-900px */
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .main-container-m {
    margin-top: 0;
    display: flex;
    flex-direction: column;
  }
  .inner-container {
    align-items: center;
    width: 100%;
  }
  .main-left {
    align-items: center;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-top: 40px;
  }
  .main-left h1 {
    margin-top: 30px;
  }
  .para-box {
    margin-top: 7px;
    width: 95%;
    align-items: left;
  }
  .demo-button {
    margin-top: 10px;
    width: 200px;
    height: 50px;
    font-size: 20px;
    font-weight: 200;
  }
  .learn-button {
    margin-top: 40px;
  }
  .main-right {
    margin-bottom: 20px;
  }
  .photo {
    display: flex;
    height: 300px;
    margin-left: 10px;
    margin-right: 10px;
    /* object-fit: cover; */
  }
}

/* media query for part2(AltraExperience) for large screen */
/* Media Query for Large screens */
@media (min-width: 1400px) {
  .main-container-m {
    margin-top: 0;
  }
  .inner-container {
    align-items: center;
    width: 100%;
  }
  .main-left {
    align-items: center;
    padding-bottom: 50px;
    /* padding-left: 20px; */
    padding-top: 40px;
  }
  .main-left h1 {
    margin-top: 50px;
  }
  .para-box {
    margin-top: 7px;
    width: 95%;
    align-items: left;
  }
  .demo-button {
    margin-top: 10px;
    width: 200px;
    height: 50px;
    font-size: 20px;
    font-weight: 200;
  }
  .learn-button {
    margin-top: 40px;
  }
  .main-right {
    margin-bottom: 0px;
  }
  .photo {
    display: flex;
    height: 420px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

/* media query for  part3- cards(how it works)*/
/* in section/section2/styles.css */

/* ************************************************************************ */

/* media query for part -4(parallax Image) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
  .parallax-image {
    height: 500px;
    margin: 20px -25px 20px -25px;
  }
  .first-image {
    top: 10px;
    display: block;
    margin-left: auto;
    left: auto;
    margin-right: auto;
    width: 53%;
  }
  .second-image {
    display: none;
  }
  .third-image {
    display: none;
  }
  .fourth-image {
    display: none;
  }
  .back-left-animate {
    overflow: hidden;
  }
  .back-right-animate {
    overflow: hidden;
  }
  .left-back {
    height: 500px;
    transform: scale(1.25);
    overflow: hidden;
  }
  .right-back {
    display: none;
    transform: scale(1.25);
    overflow: hidden;
  }
}

@media only screen and (min-width: 481px) and (max-width: 600px) {
  .parallax-image {
    height: 500px;
    margin: 20px -20px 20px -20px;
    background-image: url(../src/images/Group.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .first-image {
    top: 2px;
    display: block;
    margin-left: auto;
    left: auto;
    margin-right: auto;
    width: 45%;
  }
  .second-image {
    display: none;
  }
  .third-image {
    display: none;
  }
  .fourth-image {
    display: none;
  }

  .back-left-animate {
    visibility: 0;
  }

  .back-right-animate {
    visibility: 0;
  }
}

/* forward animation for smaller screen */
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .parallax-image {
    height: 430px;
    margin: 20px -50px 20px -50px;
  }
  .first-image {
    left: 50px;
  }
  .second-image {
    animation-name: slider-2-small;
    animation-duration: 4s;
    animation-fill-mode: forwards;
  }
  .third-image {
    animation-name: slider-3-small;
    animation-duration: 4s;
    animation-fill-mode: forwards;
  }
  .fourth-image {
    animation-name: slider-4-small;
    animation-duration: 4s;
    animation-fill-mode: forwards;
  }
  .back-left-animate {
    animation: curtain-in-s 4s;
    animation-delay: 1s;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  /* ***left right back image part  */
  @keyframes curtain-in-s {
    0% {
      left: -50%;
    }
    100% {
      left: 0%;
    }
  }
  .back-right-animate {
    animation: curtain-right-s 4s;
    animation-delay: 1s;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  @keyframes curtain-right-s {
    0% {
      right: -50%;
    }
    100% {
      right: 0%;
    }
  }
  .back-left-animate {
    overflow: hidden;
    height: 430px;
  }
  .back-right-animate {
    overflow: hidden;
    height: 430px;
  }
}

/* reverse image animation for smaller screen */
@media only screen and (min-width: 601px) and (max-width: 900px) {
  .reverse-2 {
    left: 28%;
    animation-name: reverse-slide-2-small;
    animation-duration: 4s;
    animation-fill-mode: both;
  }

  .reverse-3 {
    left: 48%;
    animation-name: reverse-slide-3-small;
    animation-duration: 4s;
    animation-fill-mode: both;
  }

  .reverse-4 {
    left: 68%;
    animation-name: reverse-slide-4-small;
    animation-duration: 4s;
    animation-fill-mode: both;
  }
}
/* ************************************ */
@keyframes slider-2-small {
  0% {
    left: 50px;
    top: 12.5%;
  }
  100% {
    left: 28%;
    top: 12.5%;
  }
}

@keyframes slider-3-small {
  0% {
    left: 50px;
    top: 12.5%;
  }
  100% {
    left: 48%;
    top: 12.5%;
  }
}

@keyframes slider-4-small {
  0% {
    left: 50px;
    top: 12.5%;
  }
  100% {
    left: 68%;
    top: 12.5%;
  }
}
/* ************************************ */
@keyframes reverse-slide-2-small {
  0% {
    left: 28%;
    top: 12.5%;
  }
  100% {
    left: 50px;
    top: 12.5%;
  }
}

@keyframes reverse-slide-3-small {
  0% {
    left: 48%;
    top: 12.5%;
  }
  100% {
    left: 50px;
    top: 12.5%;
  }
}

@keyframes reverse-slide-4-small {
  0% {
    left: 68%;
    top: 12.5%;
  }
  100% {
    left: 50px;
    top: 12.5%;
  }
}
/* ************************************* */
@media only screen and (min-width: 901px) and (max-width: 1400px) {
  .parallax-image {
    height: 600px;
    margin: 20px -100px 20px -100px;
  }
  .back-left-animate {
    animation: curtain-in-s 4s;
    animation-delay: 1s;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  @keyframes curtain-in-s {
    0% {
      left: -100%;
    }
    100% {
      left: 0%;
    }
  }
  .back-right-animate {
    animation: curtain-right-s 4s;
    animation-delay: 1s;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  @keyframes curtain-right-s {
    0% {
      right: -100%;
    }
    100% {
      right: 0%;
    }
  }

  .back-left-animate {
    overflow: hidden;
    height: 600px;
  }
  .back-right-animate {
    overflow: hidden;
    height: 600px;
  }
}

/* Media Query for parallax image section for  Large screens */
@media (min-width: 1401px) {
  .parallax-image {
    height: 750px;
    margin: 20px -100px 20px -100px;
  }
  .back-left-animate {
    animation: curtain-in-s 8s;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  @keyframes curtain-in-s {
    0% {
      left: -100%;
    }
    100% {
      left: 0%;
    }
  }
  .back-right-animate {
    animation: curtain-right-s 8s;
    animation-direction: normal;
    animation-fill-mode: forwards;
  }
  @keyframes curtain-right-s {
    0% {
      right: -100%;
    }
    100% {
      right: 0%;
    }
  }

  .back-left-animate {
    overflow: hidden;
    height: 750px;
  }
  .back-right-animate {
    overflow: hidden;
    height: 750px;
  }
}

/* *************************************************************************** */

/* media query for part 5 -footer(text part ) */
@media only screen and (max-width: 600px) {
  .footer-main {
    text-align: center;
  }
  .upper-part .foot-para {
    text-align: left;
    font-size: 16px;
  }
  .upper-part p {
    font-size: 16px;
    text-align: left;
    margin-bottom: 20px;
  }
  .upper-part h2 {
    font-size: 24px;
    text-align: left;
    margin-bottom: 30px;
  }
}

/* media queryfor  part 5 -footer part */
@media only screen and (max-width: 600px) {
  .footer-content {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    gap: 40px;
  }
  .logo-f {
    display: flex;
  }
  .span {
    width: 100vw;
  }
  .cpy {
    width: 80%;
    font-family: "SFPro-r", Poppins;
    font-size: 16px;
  }
  .cpy-1 {
    width: 80%;
    padding-bottom: 20px;
    font-family: "SFPro-r", Poppins;
    font-size: 16px;
  }
  /* hr part */
  .footer-divider {
    display: none;
  }
  .copyright {
    /* width:100%; */
    text-align: left;
  }

  .verticalSpace50px {
    padding: 0;
  }
}
/* media query for footer part for largers screen */
/* @media (min-width: 1400px) {  } */

@media only screen and (min-width: 601px) and (max-width: 1000px) {
  .left-box h1 {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .bannerContent {
    font-size: 20px;
    margin-top: 20px;
  }

  .right-box {
    width: 50%;
  }

  .demo-button {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .verticalSpace50px {
    padding: 20px;
  }
  .upper-part {
    margin-top: 50px;
  }

  .intro-content {
    height: 300px !important;
  }
  .main-left,
  .main-right {
    padding-top: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 30px;
  }

  .photo {
    align-items: center;
  }
  .photo img {
    height: 300px;
  }

  .main-left h1 {
    font-size: 30px !important;
  }

  .main-left p {
    margin-top: 20px !important;
    margin-bottom: 0 !important;
  }

  .para-box p {
    font-size: 16px;
  }
}
